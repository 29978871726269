import axios from "axios";

// const BaseURL = "http://localhost:4000";
const BaseURL = "https://apibasicweb.betonperkasa.com";

const API = axios.create({
  baseURL: BaseURL,
});

export { API, BaseURL };
