import { createStore, applyMiddleware } from 'redux'
import reducer from './reducer'
import { API } from '../config/API';

const api = store => next => async action => {
  if (action.type === 'FETCH_DATA_LOCATION') {
    let { data } = await API.get(`/our-location?sort=ASC`)

    next({
      type: 'FETCH_DATA_SUCCESS',
      payload: {
        dataLocation: data.data
      }
    })
  }
  else if (action.type === 'FETCH_DATA_CATEGORY_PRODUCT') {
    try {
      let { data } = await API.get(`/category-product?sort=ASC`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataCategoryProduct: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_SERVICE') {
    try {
      let { data } = await API.get(`/service`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataService: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_BRAND') {
    try {
      let { data } = await API.get(`/brand`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataBrand: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_PRODUCT') {
    try {
      let { data } = await API.get(`/product`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataProduct: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_PROJECT') {
    try {
      let { data } = await API.get(`/project`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataProject: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_CATEGORY_PROJECT') {
    try {
      let { data } = await API.get(`/category-project`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataCategoryProject: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_ABOUT') {
    try {
      let { data } = await API.get(`/about-us`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataAboutUs: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_NEWS') {
    try {
      let { data } = await API.get(`/news?page=0&limit=10`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataNews: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_EVENT') {
    try {
      let { data } = await API.get(`/event?page=0&limit=10`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataEvent: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_BANNER') {
    try {
      let { data } = await API.get(`/banner`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataBanner: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else if (action.type === 'FETCH_DATA_META_TAG') {
    try {
      let { data } = await API.get(`/meta-tag`)

      next({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          dataMetaTag: data.data || []
        }
      })
    } catch (err) {
      next({
        type: 'FETCH_DATA_ERROR',
        payload: err
      })
    }
  }
  else {
    next(action)
  }
}

const store = createStore(reducer, applyMiddleware(api))

export default store
