import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ "../views/Dashboard")
);
const Contact = lazy(() =>
  import(/* webpackChunkName: "contact" */ "../views/Contact")
);
const Product = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product")
);
const DetailProduct = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product/DetailProduct")
);
const Service = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Service")
);
const Project = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Project")
);
const DetailProject = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Project/DetailProject")
);
const AboutUs = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/AboutUs")
);
const DetailNewsEvent = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/AboutUs/DetailNewsEvent")
);
const Sitemap = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Sitemap")
);
function Routes() {
  return (
    <main style={{ marginTop: 64 }}>
      <Suspense
        fallback={
          <>
            <h1>Loading...</h1>
          </>
        }
      >
        <Switch>
          <Route path="/our-location" exact component={Contact} />
          <Route path="/contact-us" exact component={Contact} />
          <Route path="/product/:id" component={DetailProduct} />
          <Route path="/product" exact component={Product} />
          <Route path="/service" exact component={Service} />
          <Route path="/project/:id" component={DetailProject} />
          <Route path="/project" exact component={Project} />
          <Route path="/about-us/news/:id" exact component={DetailNewsEvent} />
          <Route path="/about-us/event/:id" exact component={DetailNewsEvent} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/sitemap" exact component={Sitemap} />
          <Route path="/" exact component={Dashboard} />
        </Switch>
      </Suspense>
    </main>
  );
}

export default Routes;
