export function setUser(payload) {
  return {
    type: 'SET_USER',
    payload
  }
}

export function fetchDataLocation() {
  return {
    type: 'FETCH_DATA_LOCATION'
  }
}

export function fetchDataCategoryProduct() {
  return {
    type: 'FETCH_DATA_CATEGORY_PRODUCT'
  }
}

export function fetchDataService() {
  return {
    type: 'FETCH_DATA_SERVICE'
  }
}

export function fetchDataBrand() {
  return {
    type: 'FETCH_DATA_BRAND'
  }
}

export function fetchDataProduct() {
  return {
    type: 'FETCH_DATA_PRODUCT'
  }
}

export function fetchDataProject() {
  return {
    type: 'FETCH_DATA_PROJECT'
  }
}

export function fetchDataCategoryProject() {
  return {
    type: 'FETCH_DATA_CATEGORY_PROJECT'
  }
}

export function fetchDataAboutUs() {
  return {
    type: 'FETCH_DATA_ABOUT'
  }
}

export function fetchDataNews() {
  return {
    type: 'FETCH_DATA_NEWS'
  }
}

export function fetchDataEvent() {
  return {
    type: 'FETCH_DATA_EVENT'
  }
}

export function fetchBanner() {
  return {
    type: 'FETCH_DATA_BANNER'
  }
}

export function fetchMetaTag() {
  return {
    type: 'FETCH_DATA_META_TAG'
  }
}