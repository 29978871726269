import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { FirstFooter, SecondFooter } from "./styleComponent";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Index({ dataCategoryProduct, dataService, dataAboutUs }) {
  const history = useHistory();
  return (
    <>
      {/* MEDSOS */}
      <Grid
        style={{
          backgroundColor: "#ffdd00",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <b style={{ marginRight: 20 }}>Follow Us</b>
          <FacebookIcon
            style={{ marginRight: 20, cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://www.facebook.com/betonperkasawijaksanaofficial/"
              )
            }
          />
          <InstagramIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.instagram.com/betonperkasawijaksana/")
            }
          />
        </Grid>
      </Grid>

      <Grid
        style={{
          backgroundColor: "#aeaeae",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "20px 0px",
            display: "flex",
          }}
        >
          <FirstFooter item xs={12} md={4}>
            <h3 style={{ margin: 0 }}>PT Beton Perkasa Wijaksana</h3>
            <h4 style={{ margin: 0, marginBottom: 20 }}>
              Total Solution Formwork & Scaffolding
            </h4>
            <b style={{ fontSize: 15 }}>Head Office</b>
            <p style={{ fontSize: 14 }}>Jl. Raya Serang Km. 16,7</p>
            <p style={{ fontSize: 14 }}>Cikupa, Tangerang 15710 - Indonesia</p>
            <p style={{ fontSize: 14 }}>Phone : +62(21) 596 0765</p>
            <p style={{ fontSize: 14 }}>Fax : +62(21) 596 0767</p>
            <p style={{ fontSize: 14 }}>Mail : bpw@betonperkasa.com</p>
            <p style={{ fontSize: 14 }}>Website : www.betonperkasa.com</p>
          </FirstFooter>

          <SecondFooter item md={8}>
            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 15 }}>Products Types</b>
              {dataCategoryProduct.map((categoryProduct, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() =>
                    history.push("/product", { id: categoryProduct.id })
                  }
                  key={`categoryProduct-${index}`}
                >
                  {categoryProduct.name}
                </p>
              ))}
            </Grid>

            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 15 }}>Our Services</b>
              {/* <p style={{ fontSize: 14 }}>Overview</p> */}
              {dataService.map((service, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => history.push("/service", { id: service.id })}
                  key={`service-${index}`}
                >
                  {service.title}
                </p>
              ))}
            </Grid>

            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 15 }}>Our Company</b>
              {dataAboutUs.map((aboutus, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => history.push("/about-us", { id: aboutus.id })}
                  key={`about-us-${index}`}
                >
                  {aboutus.title}
                </p>
              ))}
              <p
                style={{ fontSize: 14, cursor: "pointer" }}
                onClick={() => history.push("/sitemap")}
              >
                Sitemap
              </p>
            </Grid>
          </SecondFooter>
        </Grid>
      </Grid>

      <Grid
        style={{
          backgroundColor: "#303030",
          padding: "30px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <p style={{ color: "#ffffff", fontSize: 10 }}>
          © Copyright 2021 PT Beton Perkasa Wijaksana, All Right Reserved
        </p>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ dataCategoryProduct, dataService, dataAboutUs }) => {
  return {
    dataCategoryProduct,
    dataService,
    dataAboutUs,
  };
};

export default connect(mapStateToProps)(Index);
